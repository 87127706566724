.commands-page-container {
    max-width: 1400px; /* Wider container */
    margin: 0 auto;
    padding: 2rem;
    background-color: #f5f5f5;
    color: #333333;
    font-family: 'Poppins', sans-serif;
}

.page-title {
    font-size: 2.5rem;
    font-weight: 100 !important; /* Lighter font weight */
    color: #007acc;
    text-align: center;
    margin-bottom: 1.5rem;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) !important; /* Subtle shadow */
}

.categories-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.category-button {
    background-color: #ffffff;
    border: 2px solid #007acc;
    color: #007acc;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.category-button:hover {
    background-color: #007acc;
    color: #ffffff;
}

.category-button.active {
    background-color: #007acc;
    color: #ffffff;
}

.content-container {
    display: flex;
    gap: 2rem;
    height: 80vh; /* Utilize more of the page */
}

.commands-list {
    flex: 1;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: scroll; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.commands-list::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
}

.command-item {
    background-color: #ffffff;
    border: 2px solid #007acc;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box; /* Ensures padding/border doesn't increase width */
    max-width: 100%; /* Prevents exceeding parent width */
}

.command-item:hover {
    transform: scale(1.02); /* Reduce the scale factor */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.command-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333333;
}

.command-description {
    font-size: 1rem;
    color: #555555;
}

.command-details {
    flex: 1;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    gap: 1rem; /* Add spacing between elements */
    text-align: center; /* Center text within the box */
    justify-content: flex-start; /* Align items at the top */
}

.details-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007acc;
    margin-bottom: 0.5rem;
    text-align: center; /* Ensure title is centered */
}

.details-description {
    font-size: 1rem;
    color: #555555;
    text-align: center; /* Ensure description is centered */
    margin-bottom: 1rem;
}

.details-cooldown {
    font-size: 1rem;
    color: #333333;
    margin-bottom: 0.5rem;
    text-align: center; /* Center cooldown text */
}

/* Loading animation styles */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
}

.loading-cog {
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
}

.loading-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007acc;
    margin-top: 1rem;
    text-align: center;
}

.details-description {
    font-size: 1rem;
    line-height: 1.5;
    color: #555555;
    margin-bottom: 1rem;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 0.5rem;
  }

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}