/* Base styles */
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #f5f5f5; /* Light gray background */
  color: #333333; /* Dark gray text for readability */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* A container to manage layout */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Main content area grows to fill available space */
.main-content {
  flex: 1; /* Pushes the footer to the bottom */
  padding: 2rem;
}

/* Custom styles for sections */
header {
  background-color: #ffffff; /* Clean white header */
  padding: 1rem 0;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

a {
  color: #007acc; /* Professional blue for links */
  text-decoration: none;
}

a:hover {
  color: #005fa3; /* Slightly darker blue on hover */
}

button {
  background-color: #007acc; /* Blue button background */
  color: #ffffff; /* White text for contrast */
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #005fa3; /* Darker blue on hover */
}

/* Tailwind base integration */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';