.horizontal-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff; /* Matches Navbar for consistency */
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.sidebar-links {
  display: flex;
  gap: 20px; /* Space between navigation links */
}

.sidebar-links a {
  text-decoration: none;
  color: #007acc; /* Default link color */
  font-weight: bold;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
}

.sidebar-links a:hover {
  background-color: #dcdcdc;
  color: white;
}

.sidebar-links .active-link {
  background-color: #007acc; /* Highlight active link */
  color: white;
  border-radius: 5px;
}

.sidebar-user-info {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between avatar and username */
}

.sidebar-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.sidebar-username {
  font-size: 1rem;
  font-weight: bold;
  color: #007acc;
}