.items-page-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f5f5f5;
    color: #333333;
    font-family: 'Poppins', sans-serif;
}

.page-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007acc;
    text-align: center;
    margin-bottom: 1.5rem;
}

.categories-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.category-button {
    background-color: #ffffff;
    border: 2px solid #007acc;
    color: #007acc;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.category-button:hover {
    background-color: #007acc;
    color: #ffffff;
}

.category-button.active {
    background-color: #007acc;
    color: #ffffff;
}

.content-container {
    display: flex;
    gap: 2rem;
    height: auto; /* Adjust to fit content dynamically */
}

.items-list {
    flex: 1;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Vertical scroll */
    overflow-x: hidden; /* Hide horizontal scroll */
}

.item-box {
    display: flex; /* Flex layout for image and text */
    align-items: center; /* Vertically align content */
    background-color: #ffffff;
    border: 2px solid #007acc;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    text-align: left; /* Align text to the left */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    gap: 1rem; /* Space between image and content */
}

.item-box:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.item-icon {
    width: 100px;
    height: 100px;
    border-radius: 8px; /* Optional for rounded corners */
    object-fit: contain; /* Ensure the full image fits within the box */
}

.item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Spread content vertically */
    gap: 0.5rem; /* Space between text elements */
    flex: 1; /* Allow content to fill available space */
}

.item-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
}

.item-description {
    font-size: 1rem;
    color: #555555;
    line-height: 1.4; /* Improve readability */
}

.item-price {
    font-size: 1.25rem;
    color: #007acc;
    font-weight: bold;
}

.item-details {
    flex: 1;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.details-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007acc;
}

.details-icon {
    width: 120px; /* Larger detail icon */
    height: 120px;
    border-radius: 8px; /* Optional for rounded corners */
    object-fit: contain; /* Ensure the full image fits within the box */
}

.details-description {
    font-size: 1rem;
    color: #555555;
    text-align: center;
}

.details-price {
    font-size: 1.25rem;
    color: #333333;
    font-weight: bold;
}

/* Loading animation styles */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
}

.loading-cog {
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
}

.loading-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007acc;
    margin-top: 1rem;
    text-align: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Scrollbar styles (optional for aesthetic improvement) */
.items-list::-webkit-scrollbar {
    width: 6px;
}

.items-list::-webkit-scrollbar-thumb {
    background-color: #007acc;
    border-radius: 4px;
}

.items-list::-webkit-scrollbar-thumb:hover {
    background-color: #005fa3;
}