.management-page-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f5f5f5;
    color: #333333;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.page-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007acc;
    margin-bottom: 2rem;
}

.management-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns per row */
    gap: 2rem;
}

.management-box {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.management-box:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.management-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007acc;
    margin-bottom: 0.5rem;
}

.management-tag {
    font-size: 1rem;
    font-weight: 500;
    color: #555555;
    margin-bottom: 0.5rem;
}

.management-bio {
    font-size: 0.9rem;
    color: #777777;
    line-height: 1.4;
}