.subscription-container {
    max-width: 800px;
    margin: 6rem auto 0; /* Increased margin for more spacing from navbar and sidebar */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
    text-align: left;
    z-index: 1; /* Ensure content is above other elements */
    position: relative; /* Avoid stacking issues */
  }
  
  .subscription-container.no-subscription {
    text-align: center;
  }
  
  .subscription-store-button {
    background-color: #007acc;
    color: #ffffff;
    padding: 0.6rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s;
  }
  
  .subscription-store-button:hover {
    background-color: #005fa3;
    color: #ffffff;
    transform: scale(1.05);
  }
  
  .subscription-title {
    font-size: 2rem;
    color: #007acc;
    margin-bottom: 1.5rem;
  }

  .subscription-subtitle {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  .subscription-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .subscription-detail {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .subscription-icon {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
    display: block;
  }
  
  .subscription-manage-button {
    background-color: #007acc;
    color: #ffffff;
    padding: 0.6rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s;
  }
  
  .subscription-manage-button:hover {
    background-color: #005fa3;
    color: #ffffff;
    transform: scale(1.05);
  }