/* General Shop Styles */
.shop-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    position: relative;
    padding-top: 6rem; /* Adds spacing at the top for other content */
}

.divider {
    border: none;
    border-top: 2px solid #ddd;
    margin: 2rem auto; /* Centered horizontally */
    width: 80%;
}

.login-box {
    position: absolute;
    top: 20px;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10; /* Ensures it stays on top */
}

.user-details {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover; /* Ensures proper scaling */
}

.user-info {
    font-size: 0.9rem;
    font-weight: bold;
    color: #333333;
}

.login-button {
    background-color: #007acc;
    color: #ffffff;
    padding: 0.6rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s;
  }
  
  .login-button:hover {
    background-color: #005fa3;
    color: #ffffff;
    transform: scale(1.05);
  }

/* Category Titles */
.category-title {
    font-size: 2.5rem; /* Adjust font size */
    font-weight: 100; /* Medium bold */
    font-family: 'Poppins', sans-serif;
    color: #007acc; /* Same blue as "Players Online" */
    text-align: center;
    margin-bottom: 0.5rem;
    letter-spacing: 1.5px; /* Slightly spaced letters */
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.subscriptions-title {
    color: #28a745; /* Green for subscriptions */
}

.products-title {
    color: #ff5733; /* Orange for products */
}

/* Product Grid */
.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three items per row */
    gap: 1.5rem;
}

.feature-box {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    position: relative; /* Ensures "NEW" tag is positioned within this box */
}

/* Featured Product Outline */
.feature-box.featured {
    /* border: 4px solid #007acc; Blue outline */
    box-shadow: 0 0 10px #007acc, 0 0 20px rgba(0, 122, 204, 1); /* Glow effect */
}

/* NEW Tag for Featured Products */
.feature-box.featured::before {
    content: "NEW!";
    position: absolute;
    top: 15px; /* Adjust to your layout */
    left: 15px; /* Adjust to your layout */
    background-color: #007acc; /* Match the featured blue color */
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: rotate(-10deg); /* Add a slight tilt for visual flair */
    z-index: 1;
}

.sale-box {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    position: relative; /* Ensures "NEW" tag is positioned within this box */
}

/* Featured Product Outline */
.sale-box.sale {
    /* border: 4px solid #007acc; Blue outline */
    box-shadow: 0 0 10px rgb(225, 195, 25), 0 0 20px rgb(225, 195, 25); /* Glow effect */
}

/* NEW Tag for Featured Products */
.sale-box.sale::before {
    content: "SALE!";
    position: absolute;
    top: 15px; /* Adjust to your layout */
    left: 15px; /* Adjust to your layout */
    background-color: rgb(225, 195, 25); /* Match the featured blue color */
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: rotate(-10deg); /* Add a slight tilt for visual flair */
    z-index: 1;
}

.product-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
    object-fit: cover; /* Ensures proper scaling of images */
}

@media (max-width: 599px) {
    .product-grid {
        grid-template-columns: 1fr; /* Single-column layout for very narrow screens */
    }

    .feature-box {
        margin: 0 auto;
    }

    .feature-title {
        font-size: 0.9rem;
    }

    .feature-description {
        font-size: 0.7rem;
    }

    .feature-button {
        font-size: 0.8rem;
        padding: 0.5rem 0.8rem;
    }
}