/* Container styling */
.inventory-container {
  max-width: 1200px; /* Align with shop container width */
  margin: 0 auto;
  padding: 2rem; /* Consistent padding with ShopPage.css */
  text-align: center;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

/* Title styling */
.inventory-title {
  font-size: 2.5rem; /* Match category title size */
  font-weight: 100; /* Light font for professional look */
  color: #007acc; /* Same blue as ShopPage.css */
  text-align: center;
  margin-bottom: 1rem;
  letter-spacing: 1.5px; /* Consistent letter spacing */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Grid Layout */
.inventory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Auto adjust columns */
  gap: 1.5rem; /* Match product grid gap */
  justify-content: center; /* Center-align grid */
}

/* Inventory item styling */
.inventory-item {
  display: flex;
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Horizontally center content */
  justify-content: space-between; /* Space between icon and text */
  background-color: #ffffff; /* Same as feature-box background */
  padding: 1.5rem;
  border-radius: 12px; /* Match product box rounding */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Match feature-box shadow */
  text-align: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  height: 350px; /* Uniform height for all boxes */
  width: 100%; /* Ensure full width within grid cell */
  max-width: 500px; /* Restrict maximum width */
  box-sizing: border-box; /* Include padding in height/width calculation */
}

/* Optional adjustment for hover effects */
.inventory-item:hover {
  transform: scale(1.05); /* Consistent hover scaling */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on hover */
}

/* Inventory icon styling */
.inventory-icon {
  width: 120px; /* Adjusted for better scaling */
  height: 120px; /* Adjusted for better scaling */
  object-fit: cover; /* Consistent scaling for images */
  margin-bottom: 1rem;
}

/* Item info styling */
.inventory-info {
  text-align: center;
  margin-top: 1rem;
}

.inventory-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007acc; /* Match product title color */
  margin-bottom: 0.5rem;
}

.inventory-description {
  font-size: 0.9rem;
  color: #555; /* Neutral description color */
  margin-bottom: 0.5rem;
}

.inventory-amount {
  font-size: 1rem;
  font-weight: bold;
  color: #333; /* Stronger emphasis for quantity */
}

/* Button Styling */
.feature-button {
  margin-top: 2rem;
  background-color: #007acc;
  color: #ffffff;
  padding: 0.6rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s;
}

.feature-button:hover {
  background-color: #005fa3;
  transform: scale(1.05);
}

/* Error message styling */
.error-message {
  color: #e63946; /* Strong red for errors */
  font-size: 1.1rem;
  font-weight: bold; /* More emphasis on errors */
}