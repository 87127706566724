.homepage-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the main content */
    justify-content: center;
    background-color: #f5f5f5; /* Ensure uniform light gray background */
  }
  
  .homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #f5f5f5;
    text-align: center;
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centered horizontally */
    justify-content: center; /* Centered vertically */
    width: 100%;
    margin-top: 20px;
}
  
.hero-title {
    font-size: 2.5rem; /* Adjust font size */
    font-weight: 100; /* Medium bold */
    font-family: 'Poppins', sans-serif;
    color: #007acc; /* Same blue as "Players Online" */
    text-align: center;
    margin-bottom: 0.5rem;
    letter-spacing: 1.5px; /* Slightly spaced letters */
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.hero-subtitle {
    font-size: 1.2rem; /* Smaller subtitle */
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: #555555; /* Muted gray for contrast */
    text-align: center;
    margin-top: 0;
}
  
  .features-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 100%;
    margin-top: 3rem;
    justify-items: center;
  }
  
  .feature-box {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    width: 100%;
    max-width: 450px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .feature-box img {
    width: 100px;
    height: auto;
    margin: 0 auto 1rem auto;
  }
  
  .feature-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #007acc;
    margin-bottom: 0.5rem;
  }
  
  .feature-description {
    font-size: 1rem;
    color: #555555;
    margin-bottom: 1rem;
  }

  .feature-description-list {
  list-style-type: disc;
  margin: 0.5rem 0 0 1.5rem; /* Adjust margin for spacing */
  padding: 0;
}

.feature-description-list li {
  align-items: center;
  text-align: center;
  margin-bottom: 0.5rem; /* Space between items */
  line-height: 1.5; /* Better readability */
  font-size: 1.5rem; /* Slightly smaller font */
  color: #555; /* Softer color for secondary text */
}
  
  .feature-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .feature-button {
    background-color: #007acc;
    color: #ffffff;
    padding: 0.6rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s;
  }
  
  .feature-button:hover {
    background-color: #005fa3;
    color: #ffffff;
    transform: scale(1.05);
  }

/* Top-right container for stats */
.player-stats {
  position: absolute;
  top: 100px; /* Adjusted to avoid the navbar */
  right: 20px; /* Align to the right */
  width: 250px;
  background: linear-gradient(135deg, #e6f7ff, #cce7ff); /* Soft blue gradient */
  color: #007acc;
  padding: 1rem;
  border: 2px solid #007acc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Top-left container for player count */
.player-count {
  position: absolute;
  top: 100px; /* Adjusted to avoid the navbar */
  left: 20px; /* Align to the left */
  width: 250px;
  background: linear-gradient(135deg, #e6f7ff, #cce7ff); /* Soft blue gradient */
  color: #007acc;
  padding: 1rem;
  border: 2px solid #007acc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Shared styles for both boxes */
.player-box-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #005fa3;
}

.player-box p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333333;
}

.player-stats p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333333;
}

/* Shared styles for both boxes */
.player-count-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #005fa3;
}

.player-count p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333333;
}