/* General Styles for the Terms of Service Page */
.policy-page-container {
    max-width: 900px;
    margin: 50px auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .policy-page-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #007acc;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #007acc;
    padding-bottom: 0.5rem;
  }
  
  .policy-content h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007acc;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .policy-content p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
  
  .policy-content ul {
    margin: 1rem 0;
    padding-left: 1.5rem;
  }
  
  .policy-content ul li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  
  .policy-content strong {
    color: #005f8c; /* Darker shade of blue for emphasis */
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .policy-page-container {
      padding: 1rem;
    }
  
    .policy-page-title {
      font-size: 1.5rem;
    }
  
    .policy-content h2 {
      font-size: 1.25rem;
    }
  
    .policy-content p,
    .policy-content ul li {
      font-size: 0.9rem;
    }
  }