/* Footer styles */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; /* Matches navbar background */
  padding: 1rem;
  color: #333333; /* Matches navbar text color */
  border-top: 1px solid #e0e0e0; /* Light border for separation */
  font-family: 'Poppins', sans-serif; /* Consistent font */
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow at the top */
}

.footer-links {
  display: flex;
  gap: 0.5rem; /* Space between links and separators */
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-link-item {
  margin: 0;
}

.footer-links a {
  text-decoration: none;
  color: #007acc; /* Matches navbar link color */
  font-size: 1rem;
  font-weight: 300; /* Light font for professional appearance */
  transition: color 0.2s, text-decoration 0.2s;
}

.footer-links a:hover {
  color: #005fa3; /* Slightly darker blue for hover effect */
  text-decoration: underline; /* Underline on hover for emphasis */
}

.footer-separator {
  color: #007acc; /* Matches link color */
  font-size: 1rem;
  font-weight: 300;
  margin: 0 0.5rem; /* Space around the separator */
  display: inline-block;
  line-height: 1.5; /* Ensure alignment with text */
}