.policy-page-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem;
    line-height: 1.6;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .policy-page-title {
    text-align: center;
    font-size: 2rem;
    color: #007acc;
    margin-bottom: 1rem;
  }
  
  .policy-content h2 {
    font-size: 1.5rem;
    color: #333;
    margin-top: 1.5rem;
  }
  
  .policy-content ul {
    margin: 1rem 0;
    padding-left: 1.5rem;
  }
  
  .policy-content ul li {
    margin-bottom: 0.5rem;
  }
  
  .policy-content p {
    margin: 1rem 0;
  }
  
  .policy-content strong {
    color: #007acc;
  }
  
  .policy-content {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
  }