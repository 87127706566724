.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  background-color: #f9f9f9;
  font-family: 'Poppins', sans-serif;
}

.welcome-content {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

/* Inline display for avatar and username */
.welcome-user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 15px; /* Space between avatar and username */
}

.welcome-avatar-inline {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.welcome-title-inline {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007acc;
  margin: 0;
}

.welcome-message {
  font-size: 1.25rem;
  color: #333;
}

.shop-button {
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  background-color: #007acc;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shop-button:hover {
  background-color: #005f99;
  transform: scale(1.05);
}

.shop-button:active {
  background-color: #003f66;
}