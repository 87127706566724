/* SuccessPage.css */
.successpage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #e6f7ff; /* Light blue background */
  font-family: 'Poppins', sans-serif;
}

.successpage-content {
  background: #ffffff; /* White container */
  border: 2px solid #007acc; /* Light blue border */
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

.successpage-title {
  font-size: 3rem;
  font-weight: 100;
  color: #007acc;
  margin-bottom: 2rem;
}

.successpage-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.successpage-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #007acc;
  object-fit: cover;
}

.successpage-product-icon {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  object-fit: contain;
}

.successpage-message {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.successpage-product-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #111;
}

.successpage-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.successpage-button {
  background-color: #007acc;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.successpage-button:hover {
  background-color: #005fa3;
  transform: scale(1.05);
}