.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* White navbar */
  padding: 1rem;
  color: #333333; /* Dark gray text */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); Subtle shadow for separation */
  border-bottom: 1px solid #e0e0e0; /* Light border */
}

.navbar-title {
  font-size: 2.5rem; /* Slightly larger */
  font-weight: 100; /* Bold */
  font-family: 'Poppins', sans-serif; /* Ensure consistent font */
  color: #007acc; /* Professional blue */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin: 0;
}

.nav-links {
  font-weight: 100; /* Bold */
  font-size: 1.25rem;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-links a {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  color: #007acc; /* Blue links */
  font-weight: 300; /* Bold */
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: color 0.2s, background-color 0.2s;
}

.nav-links a:hover {
  color: #005fa3; /* Darker blue on hover */
  background-color: rgba(0, 0, 0, 0.05); /* Light gray hover background */
}

.navbar img {
  width: 40px; /* Slightly larger icon size */
  height: 40px;
  margin-right: 1rem; /* Space between the icon and the text */
}