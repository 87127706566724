.page-not-found-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5; /* Light gray background */
    height: 100vh; /* Full page height */
    text-align: center;
}

.not-found-title {
    font-size: 2.5rem; /* Same as hero-title */
    font-weight: 100; /* Light weight */
    font-family: 'Poppins', sans-serif;
    color: #007acc; /* Professional blue */
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-bottom: 0.5rem;
    letter-spacing: 1.5px; /* Slightly spaced letters */
}

.not-found-subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: #555555; /* Muted gray for contrast */
    margin-bottom: 2rem;
}

.go-back-button {
    background-color: #007acc; /* Blue button background */
    color: #ffffff; /* White text for contrast */
    padding: 0.6rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s;
}

.go-back-button:hover {
    background-color: #005fa3; /* Darker blue on hover */
    transform: scale(1.05); /* Slight zoom effect */
}